.FruitChoiceContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap:2rem;
  flex-wrap: wrap;
}
.selectFruit {
  padding: 2rem;
  font-size: 2rem;
  text-transform: capitalize;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3rem;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.15),
    0px 0px 18px 0px rgba(180, 180, 180, 0.25) inset;
  cursor: pointer;
}
.selectedFruit{
    color: #fff;
    background-color:  rgba(234, 126, 51, 1);
} 
.selectFruit .selectBtn {
  color: #000;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgba(223, 223, 223, 1);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
/* .selectedBtn{
    background-color: #fff !important;
} */
.selectionRight{
  color: #fff;
  background-color:rgba(96, 173, 83, 1);
}
.selectionRightIcon{
  color: #fff !important;
  background: rgba(55, 223, 102, 1) !important;
}
.selectionWrong{
  color: #fff;
  /* background: rgba(255, 165, 173, 1); */
  background: rgb(251 55 55) !important;
}
.selectionWrongIcon{
  color: #fff !important;
  background: rgba(223, 55, 55, 1) !important;
}