
.positionedBtn{
  display: block;
  position: absolute;
  right: 50%;
  top: 50%;
  margin-top: -9.2%;
  margin-right: -9.8%;
}
.nextBTn{
  margin-top: -1.2%;

}
.audio{
  bottom: 49%;
  right: 26%;
  position: absolute;
  z-index: 999;
  box-shadow: 60px 0px 30px #F17922 inset, 0px 5.16364px 18.0727px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
  width: 110px;
}
.playAudio{
  stroke-dasharray: 159;
  stroke-dashoffset: 159;
}

.pause{
  animation-play-state: paused;
}
.start{
  animation-play-state: running;
}
.player{
  animation-name: loadTimer !important;
   animation-timing-function: linear !important;
   animation-fill-mode: backwards !important;
   /* animation-duration: 4s !important; */
  /* animation:2s loadTimer linear forwards !important; */
}
@keyframes loadTimer {
 0%{
  stroke-dashoffset: 159;
 }  
 100%{
  stroke-dashoffset: 0;
 }
}
.CloseViewEmailSlide {
  position: fixed;
  top: 2%;
  right: 1%;
  width: 7rem;
  /* height: 5rem; */
  fill: #000;
  text-shadow:  0px 14px 15px rgb(0 0 0 / 40%);
  cursor: pointer;
  z-index: 999;
}
.mail{
  height: 100vh;
}
.closeMail{
  position: absolute;
  top: 1%;
  right: 4%;
  z-index: 99999;
  /* max-width: 54px !important; */
  /* height: 54px !important; */
  border-radius:50%;
  cursor: pointer;
}
.mailWrapper{
  position: fixed;
  top: 0;
  z-index: 99999;
  background-color: rgba(179, 179, 179,0.2);
  backdrop-filter: blur(4px);
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  height: 100%;
}
.mailContent{
  position: absolute;
  z-index: 99999;
  margin: 0 auto;
}
@media screen and (max-width:1440px) {
  .positionedBtn{
    transform: scale(0.8);
    margin-right: -10%;
    margin-top: -9.5%;
  }
  .nextBTn{
    margin-top: -1.9%;
  
  }
  .audio{
    bottom: 49%;
    right: 26%;
    width: 90px;
  }
}
@media screen and (max-width:1199px) {
  .positionedBtn{
    transform: scale(0.7);
    margin-right: -11%;
    font-size:1rem;
    padding:1rem 2rem;
  }
}
@media screen and (max-height:719px) {
 
  .positionedBtn{
    transform: scale(0.7);
    margin-right: -10.5%;
    margin-top: -9.5%;
  
  }
  .nextBTn{
    margin-top: -2%;
  
  }
  .CloseViewEmailSlide {
    position: fixed;
    top: 2%;
    right: 10%;
    width: 7rem;
    /* height: 5rem; */
    fill: #000;
    text-shadow:  0px 14px 15px rgb(0 0 0 / 40%);
    cursor: pointer;
    z-index: 999;
  
   }
}
 /* // loader  */
 .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}