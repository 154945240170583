.video-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.125rem;
}
.dropdown_call{
    /* position: absolute;
    right: -1.0rem;
    height: 100%; */
    width: 1.0rem;
    /* background: #535353; */
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    font-size: 0.35rem;
    cursor: pointer;
    margin-left: 0.5rem;
}

.dropdown_call input{
    position: absolute;
    /* width: 100%;
    height: 100%; */
    opacity: 0;
    cursor: pointer;
}

.dropdown_call img{
    max-width: 90%;
    transform: rotate(270deg);
    position: absolute;
}
.dropdown_call 
.icon-settings{ margin-right: 0.25rem;}

.dropdown_call_option_container{
    position: absolute;
    left: 0;
    bottom: -2.25rem;
    width: 10rem;
    height: 2rem;
    background: #5d5a58;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0rem;
    transform: scale(0);
    transform-origin: 0 0;
    transition: 0.25s;
}

/* .dropdown_call_option_container.up */
.dropdown_call_option_container
{
    bottom: auto;
    top: -2.25rem;
    background: #545454;
    transform-origin: bottom left;
}

.dropdown_call i {
    transform: rotate(0deg);
    transition: 0.25s;
    pointer-events: none;
}



.dropdown_call input:checked ~ i
{
    transform: rotate(180deg);
}
.dropdown_call input:checked ~ .dropdown_call_option_container{
    transform: scale(1);
}
