.wrapper{
    background-color: #000;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.togglePause{
    position: absolute;
    bottom: 10%;
    cursor: pointer;
}
video{
    height: 70rem;
}
