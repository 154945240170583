.wrapper{
    background-color: #000;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
.patientsWrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba(143, 90, 255, 0.6);

    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
     opacity: 1;
}
.patients{
    width: 62rem;
    height: 28rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 45px;
    color: #000;
    user-select: none;
    padding: 7rem;
    padding-bottom: 5rem;
    position: relative;
}
.btnSection{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.playButton{  
    display: block;
    padding: 1.2rem 4.5rem;
    text-align: center;
    cursor: pointer;
    border: none;
    background: #EA7E33;
    box-shadow: 0px 5.16364px 18.0727px rgba(0, 0, 0, 0.25);
    border-radius: 35.5px;
    color: #fff;
    font-size: 1.6rem;
}
.playButton img{
    width: 80%;
    margin: auto;
}
.discussLogo{
    position: absolute;
    top: -20%;
    width: 12rem;
}
.patients h1{
    padding-top: 2rem;
    padding-bottom: 4rem;
    font-size: 2.2rem !important;
    line-height: 1.2;
}