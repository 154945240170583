.dflex{
    display: flex;
    justify-content: center;
    align-items: center;
}
.container{
    width: 100%;
    height: 100vh;
    background-image: url(./bg.jpg);
    background-size: cover;
    background-position: left;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}
.container input{
    outline: 0;
}
.formContainer{
    position: absolute;
    right: 0;
    flex-direction: column;

    padding: 5.5rem 1.5rem;
    margin: 0 5%;
    
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
    border: 1px solid rgba(250, 250, 250, 0.5);
    box-sizing: border-box;
    backdrop-filter: blur(44px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 10px;
}
.formContainer img{
    margin-bottom: 2rem;
}
.formContainer form{
    flex-direction: column;
    width: 100%;
}
.formContainer form input {
    width: 100%;
    margin-bottom: 2rem;
    background: #fff0;
    border: none;
    font-size: 1.25rem;
    padding: 0.65rem 0;
    color: #fff;
    border-bottom: solid 2px #fff;
}
.formContainer form button {
    color: #fff;
    font-size: 1.5rem;
    width: 100%;
    margin-top: 3rem;
    padding: 0.75rem 0;
    background: linear-gradient(164.45deg, #D22E2E -10.29%, #C00BED 358.39%);
    border: 0.553707px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    box-shadow: 3px 4px 34px rgba(0, 0, 0, 0.25);
    border-radius: 5.53707px;
    cursor: pointer;
    transform: scale(1);
    transition: 0.25s;
}
.formContainer form button img{
    width: 2rem;
    margin-bottom: 0;
}
.formContainer form button:active {
    transform: scale(0.98);
}
.formContainer form p {
    width: 100%;
    color: #d02a43;
    font-size: 1.5rem;
}
.login_input::-webkit-input-placeholder{
    color: #fff;
  }
  .login_input:-ms-input-placeholder{
    color: #fff;
  }
  .login_input::placeholder { 
      color: #fff;
  }

@media screen and (max-width: 600px) {
    .container{
        background-position: center;
    }
    .formContainer{
        right: auto;
        margin: 0;
    }
}