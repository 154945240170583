.wrapper{
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 555;
    position: relative;
}
.close{
    position: absolute;
    font-size: 1.9rem;
    top: 2%;
    color: #F17922;
    right: 1rem;
    cursor: pointer;
    user-select: none;
    z-index: 568;
}