/* :root {
  --toastify-color-info: #3498db; 
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
} */
:root {
  --toastify-color-info: #EA7E33; 
  --toastify-color-success: #EA7E33;
  --toastify-color-warning: #EA7E33;
  --toastify-color-error: #EA7E33;
}



.loaderContainer {
  /* width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #020202de; */
  /* z-index: 15; */
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}npmk

@media only screen and (max-width: 782px) {
  .sidebar {
    width: 100%;
  }
  .sidebar__tabs {
    width: 100%;
  }
}

.menu-notification {
  position: relative;
}

.menu-notification::after {
  content: " ";
  width: 0.75rem;
  height: 0.75rem;
  background: #de1b1b;
  border-radius: 50%;
  position: absolute;
  top: -0.375rem;
  right: -0.375rem;
}

.sceneContainer {
  width: calc(100vw - 30.625rem);
  height: 100vh;
  transition: 500ms ease-in-out;
}

.sceneContainer iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.user-profile__title small span {
  display: flex;
  flex-wrap: nowrap;
}


.Toastify__toast-container--bottom-left {
  bottom: 0.65em!important;
  left: 5em!important;
}
.allOptions{
  display: flex;
  align-items: center;
  justify-content:center;
  min-height: 70%;
  width: 100%;
}