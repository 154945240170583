.audio3{
  position: absolute;
  top:28%;
  left:66%;
  /* transform: scale(1.2); */
  box-shadow:  60px 0px 30px #F17922 inset, 0px 5.16364px 18.0727px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
  width: 110px;
}

.ClosePatientSlide {
  position: fixed;
  top: 2%;
  right: 1%;
  width: 7rem;
  /* height: 5rem; */
  fill: #000;
  text-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
  cursor: pointer;
  z-index: 999;
}

.patientsText{ 
  margin-top: -25%;
  margin-left: 22%;
  position: relative;
  z-index: 9;
  top: 50%;
  left:50%
}

@media screen and (max-width: 1440px){
  .bubbleText img{
    width: 18rem;
    
  }
  .patientsText{ 
    margin-top: -21%;
    margin-left: 25%;
    position: relative;
    z-index: 9;
    top: 50%;
    left:50%
  }
  .audio3{
    top:23%;
    left:67%;
    /* transform: scale(1.2); */
    width: 90px;
  }

}
@media screen and (max-height:719px){
  .bubbleText img{
    width: 12rem;
    
  }
  .patientsText{ 
    margin-top: -28%;
    margin-left: 30%;
  }
  .audio3{
    position: absolute;
    top:23%;
    left:67%;
    /* transform: scale(1.2); */
    cursor: pointer;
    width: 80px;
  }
  .ClosePatientSlide {
    position: fixed;
    right: 10%;
  }
}
@media screen and (max-width:1024px){

  .patientsText{ 
    margin-top: -23%;
    margin-left: 23%;
  }
}