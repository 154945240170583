.wrapper{
 align-items:center;
  display:flex ;
  justify-content:center ;
  height:100%;
  background-color: #fff;
  z-index: 15;
    position: relative;
}
.wrapper2{
  width: 100vw;
  height: calc(100vw / 1.778);
  position: relative;
  background-color: #fff;
  display:flex ;
  justify-content:center ;
}
.Container{
  display:flex ;
  justify-content:center ;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
  flex-direction: column;
}
.svg{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.positionedCircle1{
  position: fixed;
  transform: translate();
  bottom: 6.5%;
  left: 28%;
  z-index: 99;
}
.positionedCircle2{

  position: fixed;
  bottom: 16%;
  left: 41.5%;
  z-index: 99;
}
.button{
  display: flex;
  justify-content:center ;
  align-items: center;
  padding: 1.2rem 3rem;
  border: none;
  outline: none;
  /* border-radius: 4.2rem; */
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  background: #F17922;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 47px;
  cursor: pointer;
  z-index: 99;
  text-align: start;

}
.footerBtn{
  background: rgba(235, 114, 0, 1);
  display: flex;
  justify-content:center ;
  align-items: center;
  padding: 1.5rem 4.3rem;
  border: none;
  outline: none;
  border-radius: 4.2rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  box-shadow:  0px 5.16364px 18.0727px rgb(0 0 0 / 25%);
  cursor: pointer;
  z-index: 99;
  text-align: center;
  transform:scale(0.6)
}
.secondaryBtn{
  color: rgba(235, 114, 0, 1);
  background-color: #fff;
}
.footer{
  display: flex;
  position: fixed;
  bottom: 3%;
  left: 2%;
  align-items: center;
}
.icon{
 transform: scale(2.5);
 width:15px;
 margin-left:-1rem;
 margin-right:-1rem;
 
}
.icon:nth-child(odd){
  margin-right: 3rem;
}
.Close{
  position: absolute;
  top: 1%;
  right: 1%;
  width: 5rem;
  height: 5rem;
  fill: #000;
  text-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 999;
}
.Close2{
  position: fixed;
  top: 2%;
  right: 1%;
  width: 7rem;
  /* height: 5rem; */
  fill: #000;
  text-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 999;
}
 @media screen and (max-height:719px) {
 
  .wrapper2{
    height: 100vh;
    width: calc(100vh * 1.778);
  }
  .footer{
   
    left: 10%;
  }
  .Close2{
    position: fixed;
    right: 10%;
    
  }
 }